import moon from "assets/images/moon.png";
const LoginBg = ({ children }) => {
  return (
    <>
      <div class="background-container">
        <div
          style={{
            backgroundColor: "red",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img src={moon} alt="" className="moon" />
        </div>
        <div class="stars"></div>
        <div class="twinkling"></div>
        <div class="clouds"></div>
      </div>
      {children}
    </>
  );
};

export default LoginBg;
