import { Box, Button, Grid, Typography } from "@mui/material";
import { BoxStyled } from "components/styled/BoxStyled";
import { TextFieldStyled } from "components/styled/TextField";
import LoginBg from "theme/LoginBg";
import { useVerificationCodeForm } from "./hooks/useVerificationCodeForm";

const VerificationCodeForm = () => {
  const {
    t,
    code,
    email,
    errors,
    setCode,
    onSubmit,
    navigate,
    register,
    handleSubmit,
  } = useVerificationCodeForm();

  return (
    <LoginBg>
      <Button
        disableOnLoading
        loading={false}
        fullWidth
        type="submit"
        variant="contained"
        sx={{
          width: 130,
          alignItems: "center",
          backgroundColor: " rgb(117, 180, 166)",
          textAlign: "center",
          ml: "50px",
          mt: "10px",

          "&:hover": {
            backgroundColor: "#078172",
            transform: "scale(1.1)",
          },
        }}
        onClick={() => navigate(-1)}
      >
        {t("back")}
      </Button>
      <Box
        sx={{
          minHeight: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",

          flexDirection: "column",
          backdropFilter: "bluer(20px)",
        }}
      >
        <BoxStyled
          sx={{
            minWidth: "350px",
            width: "30%",
            padding: "40px",
            zIndex: 10000,
            backgroundColor: "transparent",
            border: "1px solid rgba(255,255,255,0.2)",
          }}
          style={{
            backdropFilter: " blur(3px)",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" sx={{ color: "#63d2d9", mt: "10px" }}>
              {t("enter your code")}
            </Typography>

            <Typography
              variant="p"
              sx={{
                color: "lightGray.main",
                mt: "10px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {t("an email with a verification code has been sent to")} {email}
            </Typography>
          </Grid>

          <Box
            sx={{ width: "100%", mt: "30px" }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ margin: "0 0 8px 5px" }}>
              <Typography variant="inputTitle" htmlFor="code">
                {t("code")}
              </Typography>
            </Box>

            <TextFieldStyled
              type="text"
              sx={{
                width: "100%",
                "& .MuiInputBase-input": { color: "white" },
              }}
              placeholder={t("enter your code")}
              {...register("code", { validate: true })}
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              error={errors.code?.message}
              helperText={errors.code?.message || ""}
            />
            <Button
              sx={{
                display: "block",
                width: 200,
                backgroundColor: "origin.main",
                margin: "30px auto 0 auto",
                "&:hover": {
                  backgroundColor: "#078172",
                  transform: "scale(1.1)",
                },
              }}
              disableOnLoading
              loading={false}
              fullWidth
              type="submit"
              variant="contained"
            >
              {t("verify code")}
            </Button>
          </Box>
        </BoxStyled>
      </Box>
    </LoginBg>
  );
};

export default VerificationCodeForm;
